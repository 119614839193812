import React from "react";
import PropTypes from "prop-types";
import $ from 'jquery';
import imageMapResize from 'image-map-resizer';
class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: 0
    };
    this.updateIndex = this.updateIndex.bind(this);
  }


  componentDidMount() {
    console.log("test")
    setInterval(this.updateIndex, 8000);
    imageMapResize("map");
  }

  updateIndex() {
    const { images } = this.props;
    const { carouselIndex } = this.state;
    let newCarouselIndex = carouselIndex + 1;
    this.setState({ carouselIndex: newCarouselIndex > images.length - 1 ? 0 : newCarouselIndex });
  }

  renderImages() {
    const { images } = this.props;
    const { carouselIndex } = this.state;
    const renderedImages = images.map((image, index) =>
      <div id={image.id} className={`carousel-image ${carouselIndex === index ? 'carousel-image-show' : 'carousel-image-hide'}`}>
        <a href={image.url} target={image.target}>
          <img useMap={`#${image.id}`} src={image.img} />
        </a>
      </div>
    );
    return renderedImages;
  }

  render() {
    return (
      <div className="carousel-wrapper">
        {this.renderImages()}
      </div>
    )
  }
}

export default ImageCarousel;
